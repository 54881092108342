export default $axios => ({
  resource: 'moraine/user',

  /**
   * Get reset auth
   *
   * @returns {Object}
   */
  forgotPassword(data, config = {}) {
    return $axios.post('/forget-password', data, config)
  },

  /**
   * Get reset auth
   *
   * @returns {Object}
   */
  resetPassword(data, config = {}) {
    return $axios.post('/reset-password', data, config)
  },

  /**
   * Get reset auth
   *
   * @returns {Object}
   */
  updateProfile(id, data, config = {}) {
    return $axios.put(`moraine/update-profile/${id}`, data, config)
  },

  /**
   * Get list resources
   *
   * @param {Object} config
   * @returns {Object}
   */
  list(config = {}) {
    return $axios.get(`moraine/admin-user/user`, config)
  },

  /**
   * Export list resources
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  export(config = {}) {
    return $axios.get('moraine/admin-user/export-csv', config)
  },

  /**
   * Import list resources
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  import(id, config = {}) {
    return $axios.post(`moraine/admin-user/import-csv/${id}`, config)
  },

  /**
   * Delete records
   *
   * @param {Array} data
   * @param {Object} config
   */
  deleteRecords(data, config = {}) {
    return $axios.delete(`moraine/admin-user/delete-records`, data, config)
  },

  /**
   * Check upload file
   *
   * @param {Object} config
   * @returns {Object}
   */
  checkUploadFile(config = {}) {
    return $axios.get(`moraine/admin-user/check-upload-file`, config)
  },

  /**
   * Get result import
   *
   * @param {Object} config
   * @returns {Object}
   */
  getResultImport(config = {}) {
    return $axios.get(`moraine/admin-user/get-result-import`, config)
  },

  /**
   * Get result import
   *
   * @param {Object} config
   * @returns {Object}
   */
  getDataError(config = {}) {
    return $axios.get(`moraine/admin-user/get-data-error`, config)
  },

  /**
   * Delete result import
   *
   * @param {Object} config
   */
  deleteResultImport(config = {}) {
    return $axios.get(`moraine/admin-user/delete-result-import`, config)
  },

  /**
   * Create staff
   *
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  createStaff(data, config = {}) {
    return $axios.post(`moraine/admin-user/user/staff`, data, config)
  },

  /**
   * register user
   *
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  register(data, config = {}) {
    return $axios.post(`moraine/score/register`, data, config)
  },

  /**
   * Register to use other apps
   *
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  registerApp(data, config = {}) {
    return $axios.post(`moraine/score/register/app`, data, config)
  }
})
