export default $axios => ({
  resource: 'score/department-statistic',

  /**
   * Get data avg department
   *
   * @param {Object} config
   * @returns {Object}
   */
  getAvgDepartment(config = {}) {
    return $axios.get(`score/department-statistic/department/avg`, config)
  }
})
