export default $axios => ({
  resource: 'score/viewWorldStastic',
  /**
   * Get data view measument
   *
   * @param {Object} config
   * @returns {Object}
   */
  getDataScore(config = {}) {
    return $axios.get('score/scoreMeasurement', config)
  },

  /**
   * Get data ranking of user.
   *
   * @param {Object} config
   * @returns {Object}
   */
  getDataRankingOfUser(config = {}) {
    return $axios.get('score/dataRankingOfUser', config)
  },

  /**
   * Get data ranking of institution.
   *
   * @param {Object} config
   * @returns {Object}
   */
  getDataRankingOfInstitution(config = {}) {
    return $axios.get('score/dataRankingOfInstitution', config)
  }
})
