
import { mapMutations, mapState } from 'vuex'
import UserHeader from '~/components/layouts/UserHeader.vue'
import UserFooter from '~/components/layouts/UserFooter.vue'
import Sidebar from '~/components/layouts/Sidebar'
import { SET_IS_SIDEBAR_COLLAPSED } from '~/constants/mutation-types'

export default {
  components: {
    UserHeader,
    UserFooter,
    Sidebar
  },
  middleware: ['auth'],

  computed: {
    ...mapState({
      isPC: 'isPC',
      isSidebarCollapsed: 'isSidebarCollapsed'
    }),

    isCollapsed: {
      set(value) {
        this.setIsSidebarCollapsed(value)
      }
    },

    locale() {
      const locale = this.$i18n.locales.find(
        item => item.code === this.$i18n.locale
      )
      const localeISO = locale ? locale.iso : 'en-US'
      const provider = require(`ant-design-vue/es/locale-provider/${localeISO.replace(
        '-',
        '_'
      )}`)
      return provider.default
    }
  },

  methods: {
    ...mapMutations({
      setIsSidebarCollapsed: SET_IS_SIDEBAR_COLLAPSED
    }),

    /**
     * Toggle collapsed status
     */
    toggleIsCollapsed() {
      if (!this.isPC) {
        this.isCollapsed = !this.isCollapsed
      }
    }
  }
}
