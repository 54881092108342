export default $axios => ({
  resource: 'moraine/prefecture',

  /**
   * Get list resources
   *
   * @param {Object} config
   * @returns {Object}
   */
  list(config = {}) {
    return $axios.get(`moraine/admin-user/prefecture`, config)
  },

  /**
   * Get list prefecture by register
   *
   * @param {Object} config
   * @returns {Object}
   */
  listPrefecture(config = {}) {
    return $axios.get(`${this.resource}`, config)
  }
})
