
import { debounce, get, trim } from 'lodash'
import { mapState, mapMutations } from 'vuex'
import SubMenu from '~/components/organisms/SubMenu'
import MenuItem from '~/components/molecules/MenuItem'
import { SET_IS_SIDEBAR_COLLAPSED, SET_IS_PC } from '~/constants/mutation-types'
import { SANITIZE_MODE, WASH_MODE, ROLE } from '~/constants'
export default {
  components: {
    SubMenu,
    MenuItem
  },

  data() {
    return {
      SANITIZE_MODE,
      WASH_MODE,
      ROLE
    }
  },

  computed: {
    ...mapState({
      isPC: 'isPC',
      isSidebarCollapsed: 'isSidebarCollapsed'
    }),
    ...mapState({
      loading: 'loading'
    }),

    isCollapsed: {
      get() {
        return this.isSidebarCollapsed
      },
      set(value) {
        this.setIsSidebarCollapsed(value)
      }
    },

    collapsedWidth() {
      return this.isPC ? 80 : 0
    },

    menu() {
      let staticMenu = []

      if (this.$auth.user.role !== ROLE.ADMIN_SYSTEM.value) {
        staticMenu = [
          {
            icon: 'tachometer-alt',
            id: 0,
            link: '/',
            menus: [],
            parent_id: 0,
            position: 0,
            title: 'common.dashboard'
          }
        ]
      }

      const dynamicMenu = get(this, '$auth.user.menus', [])
      const menu = [...staticMenu, ...dynamicMenu]
      menu.sort((a, b) => a.position - b.position)
      return menu
    },

    selectedKeys() {
      const selectedKeys = []
      const path = this.$route.path
      const deepFind = (menu, parent = null) => {
        menu.forEach(item => {
          if (
            trim(item.link, '/') === trim(path, '/') ||
            trim(item.link, '/') === trim(path.replace(/\/\d+$/, ''), '/') ||
            trim(item.link, '/') === path.replace(/\/$/, '').split('/')[1] ||
            path.replace(/\/$/, '').split('/')[1] +
              path.replace(/\/$/, '').split('/')[2] ===
              item.link.split('/')[1] + item.link.split('/')[2]
          ) {
            if (parent) {
              selectedKeys.push(parent.id)
            }
            selectedKeys.push(item.id)
          }
          if (item.menus) {
            deepFind(item.menus, item)
          }
        })
      }
      deepFind(this.menu)
      return selectedKeys
    }
  },

  mounted() {
    this.checkIsPc()

    let resizeTimer
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer)

      resizeTimer = setTimeout(
        debounce(() => this.checkIsPc()),
        250
      )
    })
  },

  methods: {
    ...mapMutations({
      setIsSidebarCollapsed: SET_IS_SIDEBAR_COLLAPSED,
      setIsPC: SET_IS_PC
    }),

    /**
     * Checking is PC
     */
    checkIsPc() {
      const isPC =
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        ) >= 992
      this.setIsPC(isPC)
      this.isCollapsed = !isPC
    },

    /**
     * Toggle collapsed status
     */
    toggleIsCollapsed() {
      if (!this.isPC) {
        this.isCollapsed = !this.isCollapsed
      }
    }
  }
}
