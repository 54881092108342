export default $axios => ({
  resource: 'moraine/institution',
  /**
   * Get list resources
   *
   * @param {Object} config
   * @returns {Object}
   */
  list(config = {}) {
    return $axios.get('moraine/admin-user/user-institution', config)
  }
})
