/**
 * Format full date
 */
export const FORMAT_DATE_FULL = 'YYYY-MM-DD'

/**
 * Format full date time
 */
export const FORMAT_DATE_TIME_FULL = 'YYYY-MM-DD HH:mm:ss'

/**
 * Format time
 */
export const FORMAT_TIME = 'HH:mm'

/**
 * Time zone
 */
export const DEFAULT_TIMEZONE = 'Asia/Tokyo'

export const FORMAT_YEAR = 'YYYY'

export const FORMAT_MONTH = 'MM'

/**
 * Language
 */
export const DEFAULT_LANGUAGE = 'ja-JP'

/**
 * API ROOT URL
 */
export const API_ROOT_URL = process.env.API_ROOT_URL

/**
 * DEFAULT NEWS PAGING LIMIT PER PAGE
 */
export const NEWS_PAGING_LIMIT = 9

/**
 * ALPHABET JAPAN
 */
export const JAPAN_A = 1
export const JAPAN_KA = 2
export const JAPAN_SA = 3
export const JAPAN_TA = 4
export const JAPAN_NA = 5
export const JAPAN_HA = 6
export const JAPAN_MA = 7
export const JAPAN_YA = 8
export const JAPAN_RA = 9
export const JAPAN_WA = 10
export const JAPAN_DIFF = 11

/**
 *NO PREFECTURE jAPAN
 */
export const FIRST_PREFECTURE = 1
export const TWICE_PREFECTURE = 2

// model list
export const LIGHT_RATING_TRUE = 7
export const LIGHT_RATING_FAIL = 8

export const FRONT_DOOR_90TSM = 1
export const FRONT_DOOR_87TSM = 2
export const BACK_DOOR_90TSM = 3
export const BACK_DOOR_87TSM = 4
export const PRICE_FILM_BACKDOOR = 120000

// CKeditor
export const CKEDITOR_TOOLBAR_SETTING = [
  'Heading',
  '|',
  // 'ImageUpload',
  '|',
  'Bold',
  'Italic',
  'Link',
  'BulletedList',
  'NumberedList',
  '|',
  'Outdent',
  'Indent',
  'alignment',
  '|',
  'Blockquote',
  'Undo',
  'Redo'
]

export const NOT_LOGGED = 1
export const LOGGED = 2

/**
 * Score table
 */
export const SCORE_TABLE = [
  { key: 1, label: '親指上(1)' },
  { key: 2, label: '人差し指上(2)' },
  { key: 3, label: '中指上(3)' },
  { key: 4, label: '薬指上(4)' },
  { key: 5, label: '小指上(5)' },
  { key: 6, label: '親指下(6)' },
  { key: 7, label: '人差し指下(7)' },
  { key: 8, label: '中指下(8)' },
  { key: 9, label: '薬指下(9)' },
  { key: 10, label: '小指下(10)' },
  { key: 11, label: '親指～人差し指(11)' },
  { key: 12, label: '人差し指～中指(12)' },
  { key: 13, label: '中指～薬指(13)' },
  { key: 14, label: '薬指～小指(14)' },
  { key: 15, label: '手のひら/甲の上(15)' },
  { key: 16, label: '手のひら/甲の中(16)' },
  { key: 17, label: '手のひら/甲の下(17)' },
  { key: 18, label: '手首(18)' }
]

/**
 * Sort measurement date
 */
export const SORT_MEASUREMENT_DATE = [
  { key: 0, label: '日付の降順' },
  { key: 1, label: '日付の昇順' }
]

export const MONTHS = [
  { value: 1, label: '01' },
  { value: 2, label: '02' },
  { value: 3, label: '03' },
  { value: 4, label: '04' },
  { value: 5, label: '05' },
  { value: 6, label: '06' },
  { value: 7, label: '07' },
  { value: 8, label: '08' },
  { value: 9, label: '09' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' }
]

export const GENDERS = [
  { value: 0, label: '女性' },
  { value: 1, label: '男性' },
  { value: 2, label: 'その他' }
]

export const HANDS = [
  { value: 0, label: '右利き' },
  { value: 1, label: '左利き' },
  { value: 2, label: 'その他' }
]

export const ROLE_STAFF = 0
export const ROLE_ADMIN_GROUP = 3
export const ROLE_ADMIN_INSTITUTIONS = 2
export const ROLE_ADMIN_DEPT = 1
export const MAX_LENGTH = 8
/**
 * Role user
 */
export const ROLE = {
  ADMIN_SYSTEM: {
    label: 'common.admin_system',
    value: 4
  },
  Group: {
    label: 'common.group',
    value: 3
  },
  Institution: {
    label: 'common.institution',
    value: 2
  },
  Department: {
    label: 'commn.department',
    value: 1
  },
  Staff: {
    label: 'common.staff',
    value: 0
  }
}

/**
 * Status import
 */
export const STATUS_IMPORT = {
  Processing: {
    label: 'common.processing',
    value: 0
  },
  Done: {
    label: 'common.done',
    value: 1
  }
}

export const SCORE_UP = 1
export const SCORE_DOWN = 0
export const SCORE_SAME = 2
/**
 * Mode score
 */
export const WASH_MODE = 'wash'
export const SANITIZE_MODE = 'sanitize'

/**
 * Sanitizecore
 */
export const SANITIZE_SCORE = 'sanitizeScore'

/**
 * Gender
 */
export const GENDER = {
  Female: {
    label: 'common.female',
    value: 0
  },
  Male: {
    label: 'common.male',
    value: 1
  },
  Other: {
    label: 'common.other',
    value: 2
  }
}

/**
 * DOMINANT_HAND
 */
export const DOMINANT_HAND = {
  Right: {
    label: 'common.right',
    value: 0
  },
  Left: {
    label: 'common.left',
    value: 1
  },
  Other: {
    label: 'common.other',
    value: 2
  }
}
export const DESC = 0
export const ASC = 1
/**
 * Sort measurement with admin group
 */
export const SORT_MEASUREMENT_WITH_GROUP = {
  Date_asc: {
    label: 'common.sort.measurement_created_desc',
    key: 'created_at',
    order_type: DESC,
    value: 0
  },
  Date_desc: {
    label: 'common.sort.measurement_created_asc',
    key: 'created_at',
    order_type: ASC,
    value: 1
  },
  Institution_asc: {
    label: 'common.sort.measurement_institution_id_desc',
    key: 'sort_institution_id',
    order_type: DESC,
    value: 2
  },
  Institution_desc: {
    label: 'common.sort.measurement_institution_id_asc',
    key: 'sort_institution_id',
    order_type: ASC,
    value: 3
  },
  Department_asc: {
    label: 'common.sort.measurement_department_id_desc',
    key: 'sort_department_id',
    order_type: DESC,
    value: 4
  },
  Department_desc: {
    label: 'common.sort.measurement_department_id_asc',
    key: 'sort_department_id',
    order_type: ASC,
    value: 5
  }
}

/**
 * Sort measurement with admin institution
 */
export const SORT_MEASUREMENT_WITH_INSTITUTION = {
  Date_asc: {
    label: 'common.sort.measurement_created_desc',
    key: 'created_at',
    order_type: DESC,
    value: 0
  },
  Date_desc: {
    label: 'common.sort.measurement_created_asc',
    key: 'created_at',
    order_type: ASC,
    value: 1
  },
  Department_asc: {
    label: 'common.sort.measurement_department_id_desc',
    key: 'sort_department_id',
    order_type: DESC,
    value: 4
  },
  Department_desc: {
    label: 'common.sort.measurement_department_id_asc',
    key: 'sort_department_id',
    order_type: ASC,
    value: 5
  }
}

/**
 * Sort measurement with admin department
 */
export const SORT_MEASUREMENT_WITH_DEPARTMENT = {
  Date_asc: {
    label: 'common.sort.measurement_created_desc',
    key: 'created_at',
    order_type: DESC,
    value: 0
  },
  Date_desc: {
    label: 'common.sort.measurement_created_asc',
    key: 'created_at',
    order_type: ASC,
    value: 1
  }
}
/**
 * Regex
 */
export const REGEX_STRONG_PASSWORD =
  '^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{8,}$'
export const NO_WHITESPACE = /^\S*$/

export const TICK_AMOUNT = 10
export const LENGTH_ARRAY = 5
export const START = 0
export const MAX = 100
export const FIRST = 1
export const SLICE_SECOND = 2
export const SLICE_THIRD = 3
export const STEP = 5
export const ARRAY = 95

export const DEFAULT_TIME_SEARCH = '00:00:00'
export const VALUE_MODE_WASH = 1
export const VALUE_MODE_SANITIZE = 2
export const DEPARTMENT_PARENT = 0

export const MAX_CHART_RIGHT = 30
export const TICK_AMOUNT_CHART_RIGHT = 6
export const TICK_INTERVAL_CHART_RIGHT = 5
export const HEIGHT_CHART = 400
export const MAXIMUM = 100
export const MAXLENGTH = 2
export const CHART_CATEGORY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const ROLE_INSTITUTION = 2
export const APP_SCORE_CODE = 'SCORE'
export const TYPE_NOTIFICATIONS = [
  { value: 1, position: 3, label: '新機能のご案内' },
  { value: 2, position: 4, label: 'その他' },
  { value: 3, position: 2, label: 'メンテナンス情報' },
  { value: 4, position: 1, label: 'バージョンアップ情報' }
]
export const NO_PLAN = 0

/**
 * Role
 */
export const USER_ROLE = [
  {
    label: 'common.role.admin_institution',
    value: 2
  },
  {
    label: 'common.role.admin_department',
    value: 1
  },
  {
    label: 'common.role.user',
    value: 0
  }
]

export const ORDER_TYPE = {
  desc: 0,
  asc: 1
}

export const ERROR_CODE = {
  unprocessable_content: 422,
  max_plan_user_allowed: 481
}

export const MAX_COLUMN_DASHBOARD_LEFT_CHART = 4
export const LIMIT_ALL = 1000000000

/**
 * DEFAULT id app score
 */
export const APP_SCORE_ID = 1
export const START_YEAR_BIRTHDAY = 1923

/** Date release view login */
export const DATE_RELEASE = process.env.DATE_RELEASE
