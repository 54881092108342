import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faAngleRight as freeFasFaAngleRight,
  faBalanceScale as freeFasFaBalanceScale,
  faBars as freeFasFaBars,
  faCaretDown as freeFasFaCaretDown,
  faEnvelope as freeFasFaEnvelope,
  faEraser as freeFasFaEraser,
  faEye as freeFasFaEye,
  faHeading as freeFasFaHeading,
  faHome as freeFasFaHome,
  faTachometerAlt as freeFasFaTachometerAlt,
  faLock as freeFasFaLock,
  faPencilAlt as freeFasFaPencilAlt,
  faPlusCircle as freeFasFaPlusCircle,
  faSearch as freeFasFaSearch,
  faTools as freeFasFaTools,
  faServer as freeFasFaServer,
  faSignOutAlt as freeFasFaSignOutAlt,
  faTrashAlt as freeFasFaTrashAlt,
  faUserCog as freeFasFaUserCog,
  faUserFriends as freeFasFaUserFriends,
  faUserTie as freeFasFaUserTie,
  faTimes as freeFasFaTimes,
  faPaperPlane as freeFasFaPaperPlane,
  faUsers as freeFasFaUsers,
  faMapMarkerAlt as freeFasFaMapMarkerAlt,
  faPhone as freeFasFaPhone
} from '@fortawesome/free-solid-svg-icons'

library.add(
  freeFasFaAngleRight,
  freeFasFaBalanceScale,
  freeFasFaBars,
  freeFasFaCaretDown,
  freeFasFaEnvelope,
  freeFasFaEraser,
  freeFasFaEye,
  freeFasFaHeading,
  freeFasFaHome,
  freeFasFaTachometerAlt,
  freeFasFaLock,
  freeFasFaPencilAlt,
  freeFasFaPlusCircle,
  freeFasFaSearch,
  freeFasFaTools,
  freeFasFaServer,
  freeFasFaSignOutAlt,
  freeFasFaTrashAlt,
  freeFasFaUserCog,
  freeFasFaUserFriends,
  freeFasFaUserTie,
  freeFasFaTimes,
  freeFasFaPaperPlane,
  freeFasFaUsers,
  freeFasFaMapMarkerAlt,
  freeFasFaPhone
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
