import RoleRepository from './role.repository'
import InstitutionRepository from './institution.repository'
import DepartmentRepository from './department.repository'
import OccupationRepository from './occupation.repository'
import UserScoreRepository from './userScore.repository'
import MeasurementRepository from './measurement.repository'
import UserRepository from './user.repository'
import NotificationRepository from './notification.repository'
import SanitizeScoreRepository from './sanitizeScore.repository'
import prefectureRepository from './prefecture.repository'
import tagRepository from './tag.repository'
import viewWorldStasticRepository from './viewWorldStastic.repository'
import DashboardStatisticRepository from './dashboardStatistic.repository'
import PlanRepository from './plan.repository'
import CategoryRepository from './category.repository'
import PhotoRepository from './photo.repository'

/**
 * Make repository
 *
 * @param {Object} $axios
 * @param {String} resource
 * @returns {Object}
 */
const make = ($axios, resource) => ({
  resource,

  /**
   * Get list resources
   *
   * @param {Object} config
   * @returns {Object}
   */
  list(config = {}) {
    return $axios.get(`/${this.resource}`, config)
  },

  /**
   * Create resource
   *
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  create(data, config = {}) {
    return $axios.post(`/${this.resource}`, data, config)
  },

  /**
   * Show detail resource
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  show(id, config = {}) {
    return $axios.get(`/${this.resource}/${id}`, config)
  },

  /**
   * Update resource
   *
   * @param {Number} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Object}
   */
  update(id, data, config = {}) {
    return $axios.put(`/${this.resource}/${id}`, data, config)
  },

  /**
   * Delete resource
   *
   * @param {Number} id
   * @param {Object} config
   * @returns {Object}
   */
  delete(id, config = {}) {
    return $axios.delete(`/${this.resource}/${id}`, config)
  }
})

/**
 * Binding repository
 *
 * @param {Object} $axios
 * @param {Functiion} createRepository
 * @returns {Object}
 */
const bind = ($axios, createRepository) => {
  const repository = createRepository($axios)
  if (!repository.resource) {
    throw new Error('Missing resource')
  }

  return {
    ...make($axios, repository.resource),
    ...repository
  }
}

export default $axios => ({
  role: bind($axios, RoleRepository),
  user: bind($axios, UserRepository),
  userScore: bind($axios, UserScoreRepository),
  measurement: bind($axios, MeasurementRepository),
  institution: bind($axios, InstitutionRepository),
  department: bind($axios, DepartmentRepository),
  occupation: bind($axios, OccupationRepository),
  notification: bind($axios, NotificationRepository),
  sanitizeScore: bind($axios, SanitizeScoreRepository),
  tag: bind($axios, tagRepository),
  prefecture: bind($axios, prefectureRepository),
  viewWorldStastic: bind($axios, viewWorldStasticRepository),
  dashboardStatistic: bind($axios, DashboardStatisticRepository),
  plan: bind($axios, PlanRepository),
  category: bind($axios, CategoryRepository),
  photo: bind($axios, PhotoRepository)
})
