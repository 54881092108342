export default $axios => ({
  resource: 'moraine/category',
  /**
   * Get list categories.
   *
   * @param {Object} config
   * @returns {Object}
   */
  getAll(config = {}) {
    return $axios.get(`${this.resource}/all`, config)
  }
})
