export default $axios => ({
  resource: 'score/sanitize-score',
  /**
   * Export file csv payment bookRoom
   * @param {*} config
   * @returns
   */
  export(config = {}) {
    return $axios.get('score/export/sanitize-score', config)
  },

  /**
   * Get national avg score
   * @param {*} config
   * @returns
   */
  getNationalTotalScore(config = {}) {
    return $axios.get(`score/sanitize-score/national/total-avg`, config)
  },

  /**
   * Get prefecture avg score
   *
   * @param {Number} id
   * @param {*} config
   * @returns
   */
  getPrefectureAvgScore(id, config = {}) {
    return $axios.get(`score/sanitize-score/prefecture-avg-score/${id}`, config)
  },

  /**
   * Get facility deviation value of institution
   *
   * @param {Number} id
   * @param {*} config
   * @returns
   */
  getFacilityDeviationInstitution(id, config = {}) {
    return $axios.get(
      `score/sanitize-score/facility-deviation-institution/${id}`,
      config
    )
  },

  /**
   * Export file csv score admin data.
   *
   * @param {Number} id
   * @param {*} config
   * @returns
   */
  exportScoreAdminData(config = {}) {
    return $axios.get('score/sanitize-score/export-score-admin-data', config)
  },

  /**
   * get dta mode sanitize
   */
  getDataSanitize(data, config) {
    return $axios.get('score/sanitize/data-chart', data, config)
  },

  /**
   *
   * @param {} config
   * @returns
   */
  getMeasurementOfUser(config = {}) {
    return $axios.get('score/measurement/sanitize/user', config)
  },

  /**
   *
   * @param {} config
   * @returns
   */
  getAll(config = {}) {
    return $axios.get('score/measurement/sanitize/get-all', config)
  },

  /**
   * Delete records
   *
   * @param {Array} data
   * @param {Object} config
   */
  deleteRecords(data, config = {}) {
    return $axios.delete(`score/sanitize-score/delete-records`, data, config)
  }
})
