
import { mapState, mapMutations } from 'vuex'
import { SET_IS_SIDEBAR_COLLAPSED } from '~/constants/mutation-types'
import { ROLE } from '~/constants'
export default {
  data() {
    return {
      query: '',
      ROLE
    }
  },

  computed: {
    ...mapState({
      isPC: 'isPC',
      isSidebarCollapsed: 'isSidebarCollapsed'
    }),

    isCollapsed: {
      get() {
        return this.isSidebarCollapsed
      },
      set(value) {
        this.setIsSidebarCollapsed(value)
      }
    },

    currentLocaleISO() {
      const locale = this.$i18n.locales.find(
        item => item.code === this.$i18n.locale
      )
      return locale ? locale.iso : 'en-US'
    }
  },
  watch: {
    /**
     * Watching changes of route
     */
    $route(to, from) {
      const path = Object.assign({}, from.query)
      this.query = path.mode
    },
    '$route.path'() {
      this.$router.push({ query: { mode: this.query } })
    }
  },

  methods: {
    ...mapMutations({
      setIsSidebarCollapsed: SET_IS_SIDEBAR_COLLAPSED
    }),

    /**
     * Logout user
     */
    async logout() {
      this.$store.dispatch('setLoading', true)

      try {
        await this.$auth.logout()
      } catch (_) {
        this.$notification.error({
          message: this.$t('text.something_wrong')
        })
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },

    /**
     * Toggle collapsed status
     */
    toggleIsCollapsed() {
      this.isCollapsed = !this.isCollapsed
    }
  }
}
