export default $axios => ({
  resource: 'score/user-score',
  /**
   * Export file csv payment bookRoom
   * @param {*} config
   * @returns
   */
  export(config = {}) {
    return $axios.get('score/export/wash-score', config)
  },

  /**
   * Get national avg score
   * @param {*} config
   * @returns
   */
  getNationalTotalScore(config = {}) {
    return $axios.get('score/national-total-score', config)
  },

  /**
   * Get data mode wash
   */
  getDataWash(data, config) {
    return $axios.get('score/wash/data-chart', data, config)
  },

  /**
   * Get prefecture avg score
   *
   * @param {Number} id
   * @param {*} config
   * @returns
   */
  getPrefectureAvgScore(id, config = {}) {
    return $axios.get(`score/prefecture-avg-score/${id}`, config)
  },

  /**
   * Get facility deviation value of institution
   *
   * @param {Number} id
   * @param {*} config
   * @returns
   */
  getFacilityDeviationInstitution(id, config = {}) {
    return $axios.get(`score/facility-deviation-institution/${id}`, config)
  },

  /**
   * Export file csv score admin data.
   *
   * @param {Number} id
   * @param {*} config
   * @returns
   */
  exportScoreAdminData(config = {}) {
    return $axios.get('score/export-score-admin-data', config)
  },
  /**
   *
   * @param {} config
   * @returns
   */
  getMeasurementOfUser(config = {}) {
    return $axios.get('score/measurement/score/user', config)
  },

  /**
   *
   * @param {} config
   * @returns
   */
  getAll(config = {}) {
    return $axios.get('score/measurement/score/get-all', config)
  },

  /**
   * Delete records
   *
   * @param {Array} data
   * @param {Object} config
   */
  deleteRecords(data, config = {}) {
    return $axios.delete(`score/user-score/delete-records`, data, config)
  }
})
