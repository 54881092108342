export default $axios => ({
  resource: 'moraine/department',

  /**
   * Get list resources
   *
   * @param {Object} config
   * @returns {Object}
   */
  getListDepartmentToSearch(config = {}) {
    return $axios.get(`moraine/admin-user/department/list`, config)
  },
  /**
   * Get list resources
   *
   * @param {Object} config
   * @returns {Object}
   */
  getListDepartmentSearchIndex(config = {}) {
    return $axios.get(`moraine/admin-user/department/search`, config)
  }
})
