export default $axios => ({
  resource: 'moraine/photo',

  create(data, config = {}) {
    return $axios.post('moraine/photo', data, config)
  },
  calculateScore(data, config = {}) {
    return $axios.post('moraine/calculate-score', data, config)
  }
})
