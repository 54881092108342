
import { mapMutations, mapState } from 'vuex'
import { SET_IS_SIDEBAR_COLLAPSED } from '~/constants/mutation-types'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      isPC: 'isPC',
      isSidebarCollapsed: 'isSidebarCollapsed'
    }),

    isCollapsed: {
      get() {
        return this.isSidebarCollapsed
      },
      set(value) {
        this.setIsSidebarCollapsed(value)
      }
    }
  },
  methods: {
    ...mapMutations({
      setIsSidebarCollapsed: SET_IS_SIDEBAR_COLLAPSED
    }),

    /**
     * Toggle collapsed status
     */
    toggleIsCollapsed() {
      if (!this.isPC) {
        this.isCollapsed = !this.isCollapsed
      }
    }
  }
}
